class DateComponent {
    constructor() {
        this.date = new Date(new Date().toLocaleString("en-US", { timeZone: "America/New_York" }));
        this.weekdays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    }

    getSuffix(day) {
        switch (day) {
            case 1:
            case 21:
            case 31:
                return 'st';
            case 2:
            case 22:
                return 'nd';
            case 3:
            case 23:
                return 'rd';
            default:
                return 'th';
        }
    }

    getFormattedDate() {
        return `${this.weekdays[this.date.getDay()]}, ${this.date.toLocaleString('default', { month: 'long' })} ${this.date.getDate()}${this.getSuffix(this.date.getDate())} ${this.date.getFullYear()}`;
    }

    getFormattedDateYYYYMMDD() {
        const year = this.date.getFullYear();
        const month = String(this.date.getMonth() + 1).padStart(2, '0');
        const day = String(this.date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }

}

export default DateComponent;
