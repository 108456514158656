import React from 'react';
import './style/style.scss'

const Video = () => {
  return (
    <div className="video-player">
      <div id="nn_player"></div>
    </div>
  );
}

export default Video;
