import React from 'react';
import { Link } from 'react-router-dom';
import './style/style.scss';

const handleLinkClick = () => {
  console.log(typeof window.reloadAdSlots)
  if (typeof window.reloadAdSlots === 'function') {
    window.reloadAdSlots();
  }
};

const LogoButton = () => (
    <Link to="/" onClick={handleLinkClick}>
        <img src="ridellatop2.png" alt="Ridella Logo" className="logo" />
    </Link>
);

export default LogoButton;
