import React, { useState, useRef, useEffect, useCallback } from 'react';
import { Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import './style/style.scss';
import './style/archive.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock, faLockOpen, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import Confetti from 'react-confetti';
import Explanation from './Explanation';
import { XCircleFill, CheckLg } from 'react-bootstrap-icons';
import { iconMapping } from './IconMapping';
import { InformationUnlimited } from './Information';
import { Auth } from "aws-amplify";
import DateComponent from './TodaysDate';
import Cookies from 'js-cookie';

const Unlimited = () => {
    const [riddleData, setRiddleData] = useState(null);
    const [showContent, setShowContent] = useState(false);
    const [gameOver, setGameOver] = useState(false);
    const [totalGuesses, setTotalGuesses] = useState(0);
    const [isButtonDisabled, setButtonDisabled] = useState(false);
    const inputRef = useRef(null);
    const [message, setMessage] = useState('');
    const [showConfetti, setShowConfetti] = useState(false);
    const [guesses, setGuesses] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [showExplanation, setShowExplanation] = useState(false);
    const handleShowExplanation = () => setShowExplanation(true);
    const handleCloseExplanation = () => setShowExplanation(false);
    const [showInformation, setShowInformation] = useState(false);
    const handleShowInformation = () => setShowInformation(true);
    const handleCloseInformation = () => setShowInformation(false);
    const [currentIndex, setCurrentIndex] = useState(null);
    const [icon, setIcon] = useState('');
    const dateComponent = new DateComponent();
    const formattedDateYYYYMMDD = dateComponent.getFormattedDateYYYYMMDD();
    const [user, setUser] = useState(null);
    const [startTime, setStartTime] = useState(null);

    const fetchUser = useCallback(async () => {
        try {
          const currentUser = await Auth.currentAuthenticatedUser();
          setUser(currentUser);
        } catch (error) {
          console.log('User data not fetched: ', error);
        }
    }, []);

    useEffect(() => {
        if(!Cookies.get('userid')) {
            let userid = Math.random().toString(36).substring(2, 15);
            Cookies.set('userid', userid, { expires: 365 });
        }
        fetchUser();
    }, [fetchUser]);

    const clickableButtonStyle = {
        backgroundColor: 'white',
        color: 'grey',
        border: '1px solid grey',
        borderRadius: '18px',
        margin: 5,
        height: '50px',
        width: '50px',
        fontSize: '16px',
    };

    const inProgressButtonStyle = {
        ...clickableButtonStyle,
        borderColor: '#7D79D2',
        border: '2px solid',
        color: '#7D79D2',
    };

    const completedButtonStyle = {
        ...clickableButtonStyle,
        fontSize: '24px',
        borderColor: '#7D79D2',
        color: 'white',
        backgroundColor: '#7D79D2',

    };
    
    const disabledButtonStyle = {
        ...clickableButtonStyle,
        backgroundColor: 'white',
        color: '#b0b0b0',
        borderColor: '#b0b0b0',
        cursor: 'not-allowed'
    };

    const groupStyle = {
        width: '100%',                  
        marginBottom: '50px',
        marginTop: '50px',
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
    };

    const lockStyle = {
        fontSize: '34px'
    };

    useEffect(() => {
        if (showConfetti) {
          const timer = setTimeout(() => {
            setShowConfetti(false);
          }, 5000);
    
          return () => clearTimeout(timer);
        }
    }, [showConfetti]);

    const fetchRiddle = async (indexToFetch) => {
        setIsLoading(true);
        try {
            const response = await axios.get(`https://xchjxb6zaj.execute-api.us-east-1.amazonaws.com/prod?index=${String(indexToFetch)}`);
            setRiddleData(response.data);
            setShowContent(true);
            const iconName = response.data.icon;
            setIcon(iconMapping[iconName])
            setStartTime(Date.now());
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleButtonClick = (index) => {
        const storedData = localStorage.getItem('unlimitedHistory');
        if (storedData) {
            const data = JSON.parse(storedData);
            if (data.progress[index]) {
                const entry = data.progress[index];
                setGuesses(entry.guesses);
                setGameOver(entry.solved || entry.guesses.length >= 4);
                setTotalGuesses(entry.guesses.length);
                if (entry.solved) {
                    setMessage({ text: 'Correct!', status: 'correct' });
                } else if (entry.guesses.length < 4) {
                    setMessage({ text: 'Try Again!', status: 'incorrect' });
                } else {
                    setMessage({ text: 'Out of Guesses️!', status: 'incorrect' });
                }
            } else {
                setGuesses([]);
                setGameOver(false);
                setTotalGuesses(0);
            }
        } else {
            setGuesses([]);
            setGameOver(false);
            setTotalGuesses(0);
        }
        fetchRiddle(index);
        setCurrentIndex(String(index));
    };    

    const handleHitNext = async () => {
        const userId = user && user.username ? user.username : Cookies.get('userid');
        try {
            await axios.post('https://mckfupvho2.execute-api.us-east-1.amazonaws.com/prod', {
              riddledate: riddleData.date,
              userid: userId,
            });
        } catch (error) {
            console.error('There was an error!', error);
        }
    };    

    const renderLines = () => {
        if (isLoading) {
          return <p>Loading...</p>;
        }
        const lines = [riddleData.firstLine, riddleData.secondLine, riddleData.thirdLine, riddleData.fourthLine];
        const revealLines = gameOver ? 4 : totalGuesses + 1;
        return lines.map((line, index) => (
          <p key={index} className={revealLines > index ? "" : "blur-text"}>
            {line}
          </p>
        ));
    }; 

    const handleSubmit = async (event) => {
        event.preventDefault();
        setButtonDisabled(true);
        const value = inputRef.current.value.trim().toLowerCase();
        setGuesses(prevGuesses => [...prevGuesses, value]);
        setTotalGuesses(totalGuesses + 1);
        const wasCorrect = riddleData.answers.some(answer => value.includes(answer.toLowerCase()));
        storeUnlHistory(currentIndex, value, riddleData.date, wasCorrect, riddleData.icon)
        const userId = user && user.username ? user.username : Cookies.get('userid');
        const timeTaken = (Date.now() - startTime) / 1000;
        if (wasCorrect) {
            setMessage({ text: 'Correct!', status: 'correct' });
            setGameOver(true);
            setShowConfetti(true);
            setTimeout(function() { window.refreshAdSlots(); }, 1000);
        } else {
            setMessage({ text: 'Try Again!', status: 'incorrect'});
            inputRef.current.value = "";
            if (totalGuesses >= 3) {
                setMessage({ text: 'Out of Guesses️!', status: 'incorrect' });
                setGameOver(true);
                setTimeout(function() { window.refreshAdSlots(); }, 1000);
            }
            setTimeout(() => {
                setButtonDisabled(false);
            }, 1500);
        }
        try {
            await axios.post('https://s68l1gbhs8.execute-api.us-east-1.amazonaws.com/prod', {
              riddledate: riddleData.date,
              playedOn: formattedDateYYYYMMDD,
              userid: userId,
              source: "mysite",
              hitNext: false,
              guess: value,
              wasCorrect: wasCorrect,
              timeTaken: timeTaken
            });
        } catch (error) {
            console.error('There was an error!', error);
        }
    };

    const resetStates = () => {
        setShowContent(false);
        setTotalGuesses(0);
        setGameOver(false);
        setButtonDisabled(false);
        setMessage('');
        setGuesses([]);
    };

    const renderSections = (score) => {
        const sections = [];
        for (let i = 0; i < 10; i++) {
            const start = i * 30 + 1;
            const end = (i + 1) * 30;
            const isLocked = i === 0 ? false : score < start;
    
            sections.push(
                <div key={i}>
                    <div style={groupStyle}>
                        {generateButtons(start, end, isLocked)}
                    </div>
                    {i < 9 && (
                        <div style={{ textAlign: 'center' }}>
                            <FontAwesomeIcon style={lockStyle} icon={score >= end ? faLockOpen : faLock} />
                            <p style={{ fontSize: '18px' }}><strong>Level {i + 2}</strong></p>
                            {score >= start && score < end && <p>Points: {score} / {end}</p>}
                        </div>
                    )}
                </div>
            );
        }
        return sections;
    };

    const generateButtons = (start, end, disabled) => {
        const storedData = localStorage.getItem('unlimitedHistory');
        let progress = {};
        if (storedData) {
            progress = JSON.parse(storedData).progress;
        }
        let buttons = [];
        for (let i = start; i <= end; i++) {
            let buttonStyle = disabled ? disabledButtonStyle : clickableButtonStyle;
            if (progress[i]) {
                if (progress[i].solved || progress[i].guesses.length === 4) {
                    buttonStyle = completedButtonStyle;
                } else {
                    buttonStyle = inProgressButtonStyle;
                }            
            }
            const iconName = progress[i]?.icon;
            const icon = iconMapping[iconName];
            buttons.push(
                <Button 
                    key={i} 
                    variant="contained" 
                    style={buttonStyle}
                    disabled={disabled}
                    onClick={() => handleButtonClick(i)}
                >
                    {progress[i] && (progress[i].solved || progress[i].guesses.length === 4) ? (
                        <FontAwesomeIcon icon={icon} />
                        ) : (
                        '#' + i
                    )}
                </Button>
            );
        }
        return buttons;
    };
    
    const getScore = () => {
        const storedData = localStorage.getItem('unlimitedHistory');
        if (storedData) {
            const data = JSON.parse(storedData);
            return data.score;
        }
        return 0;
    };
    const score = getScore();

    function storeUnlHistory(index, guess, date, wasCorrect, icon) {
        const newEntry = {
            solved: wasCorrect,
            guesses: [guess],
            date: date,
            icon: icon
        };
        let data = {
            progress: {},
            score: 0
        };
        const storedData = localStorage.getItem('unlimitedHistory');
        if (storedData) {
            data = JSON.parse(storedData);
        }
        if (data.progress[index]) {
            data.progress[index].guesses.push(guess);
            data.progress[index].solved = wasCorrect;
        } else {
            data.progress[index] = newEntry;
        }
        data.score = Object.values(data.progress).reduce((score, entry) => {
            if (entry.solved) {
                switch (entry.guesses.length) {
                    case 1:
                        return score + 5;
                    case 2:
                        return score + 4;
                    case 3:
                        return score + 3;
                    case 4:
                        return score + 2;
                    default:
                        return score;
                }
            } else if (entry.guesses.length >= 4) {
                return score + 1;
            } else {
                return score;
            }
        }, 0);
        localStorage.setItem('unlimitedHistory', JSON.stringify(data));
    }    

    const NextRiddleButton = styled(Button)({
        backgroundColor: '#7D79D2',
        color: 'white',
        borderRadius: '25px',
        width: '150px',
        padding: '10px 0',
        textAlign: 'center',
        margin: '20px 0 20px 10px',
        cursor: 'pointer',
        fontSize: '18px',
        textTransform: 'none',    
        fontFamily: 'Comfortaa, sans-serif',
        border: '2px solid #7D79D2',
        fontWeight: 700,
        '&:hover': {
            backgroundColor: '#9B96E5',
          },
    });

    const styles = {
        backIcon: {
            cursor: 'pointer',
            fontSize: '1.5em',
            color: 'black',
            position: 'absolute',
            marginTop: '20px',
        },
    };

    return (
        <div>
            {showConfetti && <Confetti recycle={false}/>}
            <InformationUnlimited show={showInformation} handleShow={handleShowInformation} handleClose={handleCloseInformation}/>
            <div className="archiveContainer">
            <div
                className={`backdrop-test ${(showInformation || showExplanation) ? 'backdrop-show' : ''}`}
                onClick={() => {
                    handleCloseInformation();
                    handleCloseExplanation();
                }}
            ></div>
            {showContent ? (
                <div>
                    <FontAwesomeIcon icon={faArrowLeft} style={styles.backIcon} onClick={resetStates} />
                    <h3 style={{ textAlign: 'center' }}>Riddle #{currentIndex}</h3>
                    <div className="content">
                        {renderLines()}
                    </div>
                    {!gameOver && 
                        <form onSubmit={handleSubmit}>
                            <InputSection inputRef={inputRef} disabled={gameOver || isButtonDisabled} />
                        </form>
                    }
                    {gameOver && (
                            <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', justifyContent: 'center', position: 'relative', marginTop: '20px' }}>
                                <div className={`game-over-message ${message.text === "Correct!" ? "correct-result" : "incorrect-result"}`}>
                                    <span className="message-icon-result">
                                        {message.text === "Correct!" ?
                                            <CheckLg className="icon-correct" /> :
                                            <XCircleFill className="icon-incorrect" />}
                                    </span>
                                    <div>
                                        <span>{message.text}</span>
                                    </div>

                                </div >
                                <div style={{ marginTop: '10px' }}>
                                    +{message.status === "incorrect" ? 1 : 
                                        (totalGuesses === 1 ? 5 : 
                                        totalGuesses === 2 ? 4 : 
                                        totalGuesses === 3 ? 3 : 
                                        2)} point{message.status !== "incorrect" ? "s" : ""}
                                </div>
                            </div>
                    )}
                    <div className={message.status ? `${message.status}` : 'no-guesses'}>
                    {gameOver && (
                        <>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', paddingRight: 20, paddingLeft: 20, paddingBottom: 20,  paddingTop: 15 }}>
                            <div style={{ width: '50%', textAlign: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                                <div>
                                    <div style={{ fontSize: '16px' }}>Answer:</div>
                                    <div style={{ fontWeight: 'bold', fontSize: '24px' }}>{riddleData.answers[0]}</div>
                                </div>
                            </div>
                            <div style={{ width: '50%', display: 'flex', justifyContent: 'center' }}>
                                <FontAwesomeIcon icon={icon} style={{ fontSize: '54px' }} />
                            </div>
                        </div>
                        <div style={{display: 'flex', justifyContent: 'center' }}>
                            <div style={{paddingRight: 0}}>
                            <Explanation data={riddleData} show={showExplanation} handleShow={handleShowExplanation} handleClose={handleCloseExplanation}/>
                            </div>
                            <div style={{paddingRight: 20}}>
                                {parseInt(currentIndex) % 30 === 0 && parseInt(currentIndex) !== 0 ? (
                                    <NextRiddleButton
                                        onClick={() => {
                                            resetStates();
                                        }}
                                    >
                                        Main Menu
                                    </NextRiddleButton>
                                ) : (
                                    <NextRiddleButton
                                        onClick={() => {
                                            resetStates();
                                            handleButtonClick((parseInt(currentIndex) + 1).toString());
                                            handleHitNext();
                                        }}
                                    >
                                        Next Riddle
                                    </NextRiddleButton>
                                )}
                            </div>
                        </div>
                        <div className="postgame_mpu1">
                            <div id="nn_mobile_mpu1"></div>
                        </div>
                        </>
                    )}
                    {!gameOver && (
                        <div className="solve-container">
                            <div style={{ fontSize: '16px', fontWeight: 'bold', textAlign: 'right', paddingRight: 5, paddingTop: 5 }}>
                                {guesses.length || 0}/4
                            </div>
                            <div className={message.status ? `${message.status}` : 'no-guesses'}>
                                <div style={{display: 'flex', textAlign: 'left', paddingLeft: 20, paddingBottom: 10, marginTop: '-15px'}}>
                                    <div style={{fontWeight: 'bold'}}>
                                    Guesses:
                                    {guesses.length > 0 ? (
                                        guesses.map((guess, index) => (
                                        <div key={index}>{`${index + 1}. ${guess || '(blank)'}`}</div>
                                        ))
                                    ) : (
                                        <div>None</div>
                                    )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    </div>
                </div> 
            ) : (
                isLoading ? (
                    <div style={{ textAlign: 'center', padding: '20px' }}>Loading...</div>
                ) : (
                    <div className="unlimited-container">
                        <h2>Ridella Unlimited</h2>
                        {renderSections(score)}
                    </div>
                )
            )}
            </div>
            <div className="right-content">
                <div id="nn_mpu1"></div>
            </div>
        </div>
    );
}

function InputSection({ inputRef, disabled }) {

    const SolveButton = styled(Button)({
        backgroundColor: 'white',
        color: '#7D79D2',
        borderRadius: '25px',
        width: '120px',
        textAlign: 'center',
        margin: '20px 0 20px 10px',
        cursor: 'pointer',
        fontSize: '18px',
        textTransform: 'none',    
        fontFamily: 'Comfortaa, sans-serif',
        border: '2px solid #7D79D2',
        fontWeight: 700,
        '&:hover': {
            backgroundColor: '#7D79D2',
            color: 'white',
            border: '2px solid #5B5FB6',          
        },
    });

    return (
        <div className="input-section">
            <input type="text" ref={inputRef} disabled={disabled} maxLength={20} />
            <SolveButton type="submit" disabled={disabled}>
                Solve
            </SolveButton>
        </div>
    );
}

export default Unlimited;
