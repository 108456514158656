import React, { useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { QuestionCircle, X } from 'react-bootstrap-icons';
import './style/style.scss';
import { Button as MuiButton } from '@mui/material';
import { styled } from '@mui/material/styles';

let hasShownModal = false;

const Information = ({ show, handleShow, handleClose }) => {
  useEffect(() => {
    if (!hasShownModal) {
      const visited = localStorage.getItem('visited');
      if (!visited) {
        handleShow();
        hasShownModal = true;
      }
    }
  }, [handleShow]);

  return (
    <>
      <QuestionCircle onClick={handleShow} className="instruction-button" />
      <Modal show={show} onHide={handleClose} centered autoFocus={false} dialogClassName="info-modal-dialog">
        <Modal.Header>
          <Modal.Title className="exp-modal-title">
            <b>Can you solve the riddle in four tries?</b>
          </Modal.Title>
          <Button variant="none" className="close-button" onClick={handleClose}>
            <X size={28} />
          </Button>
        </Modal.Header>
        <Modal.Body>
            <br/>Start with a partially complete riddle
            <br/><br/>Each incorrect guess reveals another clue
            <br/><br/>You have one chance to solve once the entire riddle is revealed!
        </Modal.Body>
      </Modal>
    </>
  );
};

const HowToPlayButton = styled(MuiButton)({
  backgroundColor: 'white',
  color: '#7D79D2',
  borderRadius: '10px',
  width: '150px',
  textAlign: 'center',
  cursor: 'pointer',
  textTransform: 'none',
  fontFamily: 'Comfortaa, sans-serif',
  fontWeight: 700,
  border: '1px solid #7D79D2',
  '&:hover': {
    backgroundColor: '#7D79D2',
    color: 'white',
  },
});

const InformationButton = ({ show, handleShow, handleClose }) => {

  return (
    <>
      <HowToPlayButton onClick={handleShow} >
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <QuestionCircle style={{ marginRight: '8px' }} /> 
          <span style={{ marginTop: '3px' }}>How to play?</span>
        </div>
      </HowToPlayButton>
      <Modal show={show} onHide={handleClose} centered autoFocus={false} dialogClassName="info-modal-dialog">
        <Modal.Header>
          <Modal.Title className="exp-modal-title">
            <b>Can you solve the riddle in four tries?</b>
          </Modal.Title>
          <Button variant="none" className="close-button" onClick={handleClose}>
            <X size={28} />
          </Button>
        </Modal.Header>
        <Modal.Body>
            <br/>Start with a partially complete riddle
            <br/><br/>Each incorrect guess reveals another clue
            <br/><br/>You have one chance to solve once the entire riddle is revealed!
        </Modal.Body>
      </Modal>
    </>
  );
};

const InformationUnlimited = ({ show, handleShow, handleClose }) => {
  return (
    <>
      <QuestionCircle onClick={handleShow} className="instruction-button-unl" />
      <Modal show={show} onHide={handleClose} centered autoFocus={false} dialogClassName="info-modal-dialog">
        <Modal.Header>
          <Modal.Title className="exp-modal-title">
            <b>Welcome To Ridella Unlimited!</b>
          </Modal.Title>
          <Button variant="none" className="close-button" onClick={handleClose}>
            <X size={28} />
          </Button>
        </Modal.Header>
        <Modal.Body>
            <br/>Play 300 of our most popular riddles
            <br/><br/>The riddles in each level get progressively more difficult
            <br/><br/>Earn points to unlock the next set of riddles - the fewer guesses you need, the more points you earn
        </Modal.Body>
      </Modal>
    </>
  );
};

const InformationClique = ({ show, handleShow, handleClose }) => {
  return (
    <>
      <QuestionCircle onClick={handleShow} className="instruction-button-unl" />
      <Modal show={show} onHide={handleClose} centered autoFocus={false} dialogClassName="info-modal-dialog">
        <Modal.Header>
          <Modal.Title className="exp-modal-title">
            <b>Welcome To Clique!</b>
          </Modal.Title>
          <Button variant="none" className="close-button" onClick={handleClose}>
            <X size={28} />
          </Button>
        </Modal.Header>
        <Modal.Body>
            <br/>Fill in words by entering letters from the bank below.
            <br/><br/>The theme is outlined in purple from top to bottom. All answers belong to this theme.
            <br/><br/>Find non-theme words to unlock hints. Every two words found grant one hint.
        </Modal.Body>
      </Modal>
    </>
  );
};

const InformationProximity = ({ show, handleShow, handleClose }) => {
  return (
    <>
      <QuestionCircle onClick={handleShow} className="instruction-button-unl" />
      <Modal show={show} onHide={handleClose} centered autoFocus={false} dialogClassName="info-modal-dialog">
        <Modal.Header>
          <Modal.Title className="exp-modal-title">
            <b>Welcome To Proximity!</b>
          </Modal.Title>
          <Button variant="none" className="close-button" onClick={handleClose}>
            <X size={28} />
          </Button>
        </Modal.Header>
        <Modal.Body>
            <br/>Try to find the target word by guessing nouns from the predefined list.
            <br/><br/>Words are ranked by AI in order of semantic similarity to the target word.
            <br/><br/>This means that words are ordered by their context and meaning, not their spelling or sound.
            <br/><br/>The closer you are, the lower the rank of your guess.
        </Modal.Body>
      </Modal>
    </>
  );
};

export { Information, InformationButton, InformationUnlimited, InformationClique, InformationProximity };
