import React, { useState } from 'react';
import { InformationClique } from './Information';

const Clique = ({ width = '100%', height = '800px', topPadding = '0px' }) => {
    const [showInformation, setShowInformation] = useState(false);
    const handleShowInformation = () => setShowInformation(true);
    const handleCloseInformation = () => setShowInformation(false);
  return (
    <div className="clique-container" style={{ paddingTop: topPadding }}>
        {/* <InformationClique show={showInformation} handleShow={handleShowInformation} handleClose={handleCloseInformation}/> */}
      <iframe
        src="https://ridella.app/clique"
        width={width}
        height={height}
        frameBorder="0"
        allowFullScreen
        title="Ridella Clique"
      />
    </div>
  );
};

export default Clique;