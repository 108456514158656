import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
  const [style, setStyle] = useState({
    display: 'flex',
    gap: '20px',
    justifyContent: 'center',
    fontSize: '12px',
    paddingBottom: '50px',
    paddingTop: '20px',
  });

  const updateStyleForScreenSize = () => {
    const newStyle = {
      ...style,
      // marginLeft: window.innerWidth > 950 ? '280px' : '0px',
    };
    setStyle(newStyle);
  };

  useEffect(() => {
    window.addEventListener('resize', updateStyleForScreenSize);
    // Set the initial style based on the current window size
    updateStyleForScreenSize();

    return () => {
      window.removeEventListener('resize', updateStyleForScreenSize);
    };
  }, []);

  const linkStyles = {
    color: 'inherit',
    textDecoration: 'none',
  };

  return (
    <div style={style}>
      <Link to="/privacy" style={linkStyles}>Privacy Policy</Link>
      <Link to="/?cmpscreen" style={linkStyles}>Manage Cookies</Link>
    </div>
  );
}

export default Footer;
