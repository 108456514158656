import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { Trophy, Calendar, Envelope, InfoCircle, PlayCircle, BarChart, FileText, Infinity } from 'react-bootstrap-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSquareEllipsis, faBullseyeArrow } from '@awesome.me/kit-636b1434d3/icons/classic/light';
// import Footer from './Footer';

const LeftNavBar = () => {
  const [isVisible, setIsVisible] = useState(window.innerWidth > 950);

  useEffect(() => {
    const handleResize = () => {
      setIsVisible(window.innerWidth > 950);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const tabStyle = {
    display: 'block',
    padding: '10px 10px 10px 30px',
    textDecoration: 'none',
    color: 'black',
    cursor: 'pointer',
  };

  const activeTabStyle = {
    ...tabStyle,
    backgroundColor: '#ccc',
    borderLeft: '4px solid #7D79D2',
  };

  const navBarStyle = {
    display: isVisible ? 'block' : 'none',
    position: 'fixed',
    left: 0,
    top: '50px',
    height: 'calc(100% - 50px)',
    width: '280px',
    overflow: 'auto',
    backgroundColor: '#faf7f5',
    paddingLeft: '10px',
  };

  const badgeStyle = {
    backgroundColor: '#7D79D2',
    color: 'white',
    padding: '2px 6px',
    borderRadius: '10px',
    fontSize: '0.7em',
    marginLeft: '10px',
    fontWeight: 'bold',
  };

  return (
    <div style={navBarStyle}>
      <NavLink to="/" style={({ isActive }) => (isActive ? activeTabStyle : tabStyle)}><PlayCircle className="icon" /> Today's Riddle</NavLink>
      <NavLink to="/clique" style={({ isActive }) => (isActive ? activeTabStyle : tabStyle)}>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <span>
            <FontAwesomeIcon size="xl" style={{ marginLeft: '2px', marginRight: '8px' }} icon={faSquareEllipsis} />
            Clique
          </span>
          {/* <span style={badgeStyle}>NEW!</span> */}
        </div>
      </NavLink>
      <NavLink to="/proximity" style={({ isActive }) => (isActive ? activeTabStyle : tabStyle)}>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <span>
            <FontAwesomeIcon size="xl" style={{ marginLeft: '2px', marginRight: '8px' }} icon={faBullseyeArrow} />
            Proximity
          </span>
          {/* <span style={badgeStyle}>NEW!</span> */}
        </div>
      </NavLink>
      <a href="https://www.ridella.xyz/blog" rel="noopener noreferrer" style={tabStyle}><FileText className="icon" /> Blog</a>
      {/* <NavLink to="/about" style={({ isActive }) => (isActive ? activeTabStyle : tabStyle)}><InfoCircle className="icon" /> About</NavLink> */}
      {/* <NavLink to="/archive" style={({ isActive }) => (isActive ? activeTabStyle : tabStyle)}><Calendar className="icon" /> Archive</NavLink> */}
      <NavLink to="/unlimited" style={({ isActive }) => (isActive ? activeTabStyle : tabStyle)}><Infinity className="icon" /> Unlimited</NavLink>
      <NavLink to="/globalstats" style={({ isActive }) => (isActive ? activeTabStyle : tabStyle)}><BarChart className="icon" /> Global Stats</NavLink>
      <NavLink to="/leaderboard" style={({ isActive }) => (isActive ? activeTabStyle : tabStyle)}><Trophy className="icon" /> Leaderboard</NavLink>
      <NavLink to="/contact" style={({ isActive }) => (isActive ? activeTabStyle : tabStyle)}><Envelope className="icon" /> Contact</NavLink>

      {/* <Footer /> */}
    </div>
  );
};

export default LeftNavBar;
