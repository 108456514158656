import React, { useState } from 'react';
import { InformationProximity } from './Information';

const Proximity = ({ width = '100%', height = '800px', topPadding = '0px' }) => {
    const [showInformation, setShowInformation] = useState(false);
    const handleShowInformation = () => setShowInformation(true);
    const handleCloseInformation = () => setShowInformation(false);

    return (
        <div style={{ paddingTop: topPadding }}>
            <InformationProximity 
                show={showInformation} 
                handleShow={handleShowInformation} 
                handleClose={handleCloseInformation}
            />
            <div className="iframe-container">
                <iframe
                    src="https://ridella.app/proximity"
                    width={width}
                    height={height}
                    allowFullScreen
                    title="Proximity"
                />
            </div>
            <style jsx>{`
                .iframe-container {
                    width: 100%;
                }
                @media (min-width: 951px) and (max-width: 1100px) {
                    .iframe-container {
                        margin-left: 100px;
                    }
                }
            `}</style>
        </div>
    );
};

export default Proximity;