import React from 'react';
import { Modal, Button as BootstrapButton } from 'react-bootstrap';
import { X } from 'react-bootstrap-icons';
import './style/style.scss';
import { Button as MuiButton } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledButton = styled(MuiButton)({
  backgroundColor: 'white',
  color: '#7D79D2',
  borderRadius: '25px',
  width: '150px',
  padding: '10px 0',
  textAlign: 'center',
  margin: '20px 0 20px 10px',
  cursor: 'pointer',
  fontSize: '18px',
  textTransform: 'none',
  fontFamily: 'Comfortaa, sans-serif',
  fontWeight: 700,
  border: '2px solid #7D79D2',
  '&:hover': {
    backgroundColor: '#7D79D2',
    color: 'white',
    border: '2px solid #5B5FB6',
  },
});

const Explanation = ({ data, show, handleShow, handleClose }) => {

  const handleButtonClick = () => {
    expButtonCounter();
    handleShow();
  };

  return (
    <>      
      <StyledButton onClick={handleButtonClick}>Explanation</StyledButton>
      <Modal show={show} onHide={handleClose} centered autoFocus={false} dialogClassName="info-modal-dialog">
        <Modal.Header>
          <Modal.Title className="exp-modal-title">
            <b>Explanation</b>
          </Modal.Title>
          <BootstrapButton variant="none" className="close-button" onClick={handleClose}>
            <X size={28} />
          </BootstrapButton>
        </Modal.Header>
        <Modal.Body className="exp-modal-body">
            <br/><strong>{data.firstLine}</strong> - {data['1ex']}
            <br/><br/><strong>{data.secondLine}</strong> - {data['2ex']}
            <br/><br/><strong>{data.thirdLine}</strong> - {data['3ex']}
            <br/><br/><strong>{data.fourthLine}</strong> - {data['4ex']}
        </Modal.Body>
      </Modal>
    </>
  );
};

function expButtonCounter() {
  const apiUrl = "https://7z7przcw81.execute-api.us-east-1.amazonaws.com/prod";
  const params = {
      action: "expButton"
  };
  fetch(apiUrl, {
    method: 'POST', 
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(params)
  })
  .then(response => response.json())
  .catch(error => {
    console.error('Error incrementing counter:', error);
  });
}

export default Explanation;
