import React, { Suspense, lazy, useEffect, useState } from 'react';
import { BrowserRouter, Routes, Route, useLocation } from 'react-router-dom';
import { Authenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import './App.css';
import Video from './Ridella/Video';
import Menu from './Ridella/Menu';
import LeftNavBar from './Ridella/LeftNavBar';
import LogoButton from './Ridella/LogoButton';
import Footer from './Ridella/Footer';
import Unlimited from './Ridella/Unlimited';
import Clique from './Ridella/Clique';
import Proximity from './Ridella/Proximity';
import './Ridella/style/Menu.scss';

const { PUBLIC_URL } = process.env;

const Ridella = lazy(() => import('./Ridella'));
const About = lazy(() => import('./Ridella/About'));
const Leaderboard = lazy(() => import('./Ridella/Leaderboard'));
const GlobalStats = lazy(() => import('./Ridella/GlobalStats'));
const Archive = lazy(() => import('./Ridella/Archive'));
const SignUp = lazy(() => import('./Ridella/SignUp'));
const Contact = lazy(() => import('./Ridella/Contact'));
const Platform = lazy(() => import('./Ridella/Platform'));
const PrivacyPolicy = lazy(() => import('./Ridella/PrivacyPolicy'));
const TermsOfService = lazy(() => import('./Ridella/TermsOfService'));
const NotFound = lazy(() => import('./pages/NotFound'));

const Authenticated = ({ children }) => (
  <div style={{ paddingTop: '50px' }}>
    <Authenticator>{children}</Authenticator>
  </div>
);

const MenuWrapper = () => {
  const location = useLocation();
  const resetStates = () => {
    console.log("Resetting states");
  };
  return (
    <Menu onArchiveClick={location.pathname.includes('/archive') ? resetStates : undefined} />
  );
};

const App = () => {

    const loadAds = () => {
        window.AdSlots.cmd.push(function() {
            var bidsReady = new Promise((resolve) => {
                window.addEventListener('nn_bids_ready', () => resolve());
                setTimeout(resolve, 3000);
            });
            bidsReady.then(() => {
                console.log('Loading ad slots now (first page)');
                window.refreshAdSlots();
            });
        });
    };

    useEffect(() => {
        loadAds();
    }, []);

  return (
    <BrowserRouter basename={PUBLIC_URL}>
      <Video />
      <div>
        <div className="button-container-menu">
          <LogoButton />
          {/* <Menu /> */}
          <MenuWrapper />
        </div>
        <LeftNavBar />
      </div>
      <div id="nn_mobile_lb1"></div>
      <div id="nn_lb2"></div>
      <div className="main-content-container">
        <div className="content-container">
          <Suspense fallback={<div>Loading...</div>}>
            <Routes>
              <Route path="/" element={<Ridella />} />
              <Route path="/about" element={<About />} />
              <Route path="/leaderboard" element={<Leaderboard />} />
              <Route path="/globalstats" element={<GlobalStats />} />
              <Route path="/archive" element={<Archive />} />
              <Route path="/signup" element={<Authenticated><SignUp /></Authenticated>} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/platform" element={<Platform />} />
              <Route path="*" element={<NotFound />} />
              <Route path="/privacy" element={<PrivacyPolicy />} />
              <Route path="/tos" element={<TermsOfService />} />
              <Route path="/unlimited" element={<Unlimited />} />
              <Route path="/clique" element={<Clique />} />
              <Route path="/proximity" element={<Proximity />} />
            </Routes>
          </Suspense>      
        </div>
        <div className="footer-container">
          <Footer />
        </div>
      </div>
    </BrowserRouter>
  );
};

export default App;
