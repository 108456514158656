import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import ReactGA from 'react-ga4';
import { createBrowserHistory } from 'history';
import { Amplify } from 'aws-amplify';
import awsconfig from './aws-exports';

Amplify.configure(awsconfig);

ReactGA.initialize([
  { trackingId: 'G-6TH4V9F877' },
  { trackingId: 'G-CXYYY1VCFM', gaOptions: { name: 'Ridella2' } }
]);

// Initialize google analytics page view tracking
const history = createBrowserHistory();

history.listen(location => {
  ReactGA.send({ page_path: location.pathname });
  ReactGA.send({ page_path: location.pathname }, 'Ridella2');
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
