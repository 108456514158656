import React, { useState, useEffect, useRef } from 'react';
import { Trophy, Calendar, Envelope, InfoCircle, List, BarChart, FileText, PlayCircle, Infinity } from 'react-bootstrap-icons';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSquareEllipsis, faBullseyeArrow } from '@awesome.me/kit-636b1434d3/icons/classic/light';
import './style/Menu.scss';

const Menu = ({ onArchiveClick }) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const dropdownRef = useRef(null);

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setShowDropdown(false);
    }
  };

  const handleLinkClick = () => {
    setShowDropdown(false);
    console.log(typeof window.reloadAdSlots)
    if (typeof window.reloadAdSlots === 'function') {
      setTimeout(function() { window.refreshAdSlots(); }, 1000);
    }
  };
  
  const handleArchiveClick = () => {
    if (onArchiveClick) {
      onArchiveClick();
    }
    handleLinkClick();
  };

  const badgeStyle = {
    backgroundColor: '#7D79D2',
    color: 'white',
    padding: '2px 6px',
    borderRadius: '10px',
    fontSize: '0.7em',
    marginLeft: '10px',
    fontWeight: 'bold',
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="menu-container" ref={dropdownRef}>
        <button className="menu-button" onClick={() => setShowDropdown(!showDropdown)}>
        <List className="hamburger-icon" />
        </button>
      {showDropdown && (
        <div className="dropdown">
            <Link to="/" onClick={handleLinkClick}>
              <PlayCircle className="icon" /> Today's Riddle
            </Link>
            <Link to="/clique" onClick={handleLinkClick}>
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <span>
                  <FontAwesomeIcon size="lg" style={{ marginRight: '8px' }} icon={faSquareEllipsis} />
                  Clique
                </span>
                {/* <span style={badgeStyle}>NEW!</span> */}
              </div>
            </Link>
            <Link to="/proximity" onClick={handleLinkClick}>
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <span>
                  <FontAwesomeIcon size="lg" style={{ marginRight: '8px' }} icon={faBullseyeArrow} />
                  Proximity
                </span>
                {/* <span style={badgeStyle}>NEW!</span> */}
              </div>
            </Link>
            <a href="https://www.ridella.xyz/blog" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'inherit' }}>
              <FileText className="icon" /> Blog
            </a>
            {/* <Link to="/about" onClick={handleLinkClick}>
              <InfoCircle className="icon" /> About
            </Link> */}
            {/* <Link to="/archive" onClick={handleArchiveClick}>
              <Calendar className="icon" /> Archive
            </Link> */}
            <Link to="/unlimited" onClick={handleArchiveClick}>
              <Infinity className="icon" /> Unlimited
            </Link>
            <Link to="/globalstats" onClick={handleLinkClick}>
              <BarChart className="icon" /> Global Stats
            </Link>
            <Link to="/leaderboard" onClick={handleLinkClick}>
              <Trophy className="icon" /> Leaderboard
            </Link>
            <Link to="/contact" onClick={handleLinkClick}>
              <Envelope className="icon" /> Contact
            </Link>

        </div>
      )}
    </div>
  );
};

export default Menu;
