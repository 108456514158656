import {
    faRainbow,
    faPiano,
    faCow,
    faGrapes,
    faSprinkler,
    faHammer,
    faCloudFog,
    faGlasses,
    faEarthAmericas,
    faBalloon,
    faRing,
    faJugBottle,
    faGhost,
    faPhotoFilm,
    faPizza,
    faElephant,
    faHoneyPot,
    faWineBottle,
    faWindowFrame,
    faReflectHorizontal,
    faLollipop,
    faStaff,
    faBacterium,
    faSunDust,
    faMicroscope,
    faGavel,
    faLeaf,
    faSoap,
    faBanana,
    faBottleWater,
    faTombstone,
    faBroomWide,
    faBadgerHoney,
    faSchool,
    faCoffeePot,
    faCubesStacked,
    faCauldron,
    faAnt,
    faFaceRaisedEyebrow,
    faSword,
    faUmbrella,
    faBoxingGlove,
    faHourglassStart,
    faPersonWalkingWithCane,
    faVirus,
    faGuitar,
    faSquareRoot,
    faScissors,
    faLightbulb,
    faScroll,
    faFaceSmile,
    faMessage,
    faBone,
    faCodeBranch,
    faTreePalm,
    faConveyorBeltEmpty,
    faHouseNight,
    faCactus,
    faPie,
    faSailboat,
    faFrog,
    faElevator,
    faKiwiBird,
    faHandcuffs,
    faMoneyBill,
    faKey,
    faPlug,
    faDolphin,
    faShuttleSpace,
    faSprayCanSparkles,
    faFlowerDaffodil,
    faMugTea,
    faComment,
    faHatCowboy,
    faBoot,
    faSkullCrossbones,
    faDownToLine,
    faBird,
    faBagSeedling,
    faShirtLongSleeve,
    faCircle,
    faIslandTropical,
    faTemperatureThreeQuarters,
    faTire,
    faClothesHanger,
    faGlobe,
    faFaceLaughWink,
    faWhistle,
    faMusic,
    faStomach,
    faMagnet,
    faBaby,
    faSheep,
    faBottleBaby,
    faMoon,
    faSpoon,
    faComet,
    faBrain,
    faSmoke,
    faMasksTheater,
    faHeart,
    faScarf,
    faBook,
    faCloud,
    faPickaxe,
    faAtom,
    faFish,
    faSkiBootSki,
    faBenchTree,
    faBoothCurtain,
    faUtilityPole,
    faCoffeeBean,
    faHandshake,
    faBatteryBolt,
    faTomato,
    faDoorClosed,
    faWaterArrowUp,
    faDove,
    faUser,
    faLasso,
    faBeerMug,
    faCamera,
    faThoughtBubble,
    faRabbit,
    faSpider,
    faTimeline,
    faChess,
    faGoalNet,
    faRoad,
    faSyringe,
    faBlockBrick,
    faLink,
    faPotato,
    faWhiskeyGlassIce,
    faFaceLaughBeam,
    faTent,
    faTrain,
    faClock,
    faTurtle,
    faPenNib,
    faMushroom,
    faUserCowboy,
    faRollerCoaster,
    faTeddyBear,
    faMountains,
    faSquareRootVariable,
    faText,
    faLockKeyhole,
    faEarthOceania,
    faDinosaur,
    faSheetPlastic,
    faToolbox,
    faAppleWhole,
    faDragon,
    faSignHanging,
    faShovel,
    faGear,
    faPersonMilitaryPointing,
    faBee,
    faPenFancy,
    faBallotCheck,
    faUmbrellaBeach,
    faPlanetRinged,
    faCloudRain,
    faFlower,
    faMicrowave,
    faBell,
    faMonkey,
    faLips,
    faTurkey,
    faEye,
    faEarListen,
    faSpiderWeb,
    faArchway,
    faSkeletonRibs,
    faDrumstick,
    faWorm,
    faPersonFairy,
    faUserHairLong,
    faHandFingersCrossed,
    faWaffle,
    faNewspaper,
    faEar,
    faHand,
    faCatSpace,
    faFlag,
    faCitrus,
    faHandBackPointUp,
    faNfc,
    faPeoplePulling,
    faBreadSlice,
    faDroplet,
    faShirt,
    faBowArrow,
    faMitten,
    faTeethOpen,
    faDice,
    faSnowflake,
    faFishingRod,
    faFlowerTulip,
    faMap,
    faCoin,
    faRectangleWide,
    faBoltLightning,
    faFeather,
    faEngine,
    faSunrise,
    faCat,
    faVolleyball,
    faPlantWilt,
    faFaceSmileBeam,
    faScaleBalanced,
    faChessKing,
    faCrab,
    faFence,
    faFaceGrinTongue,
    faCakeCandles,
    faSun,
    faGun,
    faCards,
    faBridge,
    faSnake,
    faVolcano,
    faWhale,
    faKite,
    faBed,
    faHelmetBattle,
    faCampfire,
    faWater,
    faSkull,
    faParachuteBox,
    faPersonSimple,
    faDog,
    faRingsWedding,
    faPencil,
    faCitrusSlice,
    faFanTable,
    faBowlRice,
    faNose,
    faSeedling,
    faAngleUp,
    faFutbol,
    faSignature,
    faKnife,
    faFaceSmilingHands,
    faBug,
    faWind,
    faRug,
    faShip,
    faCity,
    faMouseField,
    faCompass,
    faPig,
    faLandMineOn,
    faFamily,
    faTruckLadder,
    faFireplace,
    faPaintRoller,
    faHillRockslide,
    faOnion,
    faSkeleton,
    faCameraMovie,
    faSmoking,
    faCoins,
    faRacquet,
    faHorse,
    faThumbsUp,
    faBooks,
    faCandleHolder,
    faDrum,
    faReel,
    faStars,
    faCars,
    faTree,
    faCandyCane,
    faPenToSquare,
    faWave,
    faEgg,
    faGem,
    faHandSparkles,
    faCode,
    faFaceSleeping,
    faHandDots,
    faMoneyBillTrendUp,
    faFire,
    faTrash,
    faBicycle,
    faMattressPillow,
    faMaskVentilator,
    faCalendarDays,
    faPawClaws,
    faRocket,
    faLungs,
    faRadio,
    faTooth,
    faBlanket,
    faBat,
    faSocks,
    faCandyBar,
    faPalette,
    faDna,
    faFaceSadTear,
    faArrowDown19,
    faPersonRunning,
    faCheeseSwiss,
    faLemon,
    faBowlSoftServe,
    faChessQueen,
    faBrush,
    faKidneys,
    faFileZipper,
    faEclipse,
    faCorn,
    faGlass,
    faFaceExhaling,
    faGolfFlagHole,
    faMug,
    faCartShopping,
    faTemperatureFull,
    faTablePicnic,
    faFaceSmileHorns,
    faHouseTree,
    faPlateUtensils,
    faRectangleBarcode
} from '@awesome.me/kit-636b1434d3/icons/classic/solid';

export const iconMapping = {
    'fa-rainbow': faRainbow,
    'fa-piano': faPiano,
    'fa-cow': faCow,
    'fa-grapes': faGrapes,
    'fa-sprinkler': faSprinkler,
    'fa-hammer': faHammer,
    'fa-cloud-fog': faCloudFog,
    'fa-glasses': faGlasses,
    'fa-earth-americas': faEarthAmericas,
    'fa-balloon': faBalloon,
    'fa-ring': faRing,
    'fa-jug-bottle': faJugBottle,
    'fa-ghost': faGhost,
    'fa-photo-film': faPhotoFilm,
    'fa-pizza': faPizza,
    'fa-elephant': faElephant,
    'fa-honey-pot': faHoneyPot,
    'fa-wine-bottle': faWineBottle,
    'fa-window-frame': faWindowFrame,
    'fa-reflect-horizontal': faReflectHorizontal,
    'fa-lollipop': faLollipop,
    'fa-staff': faStaff,
    'fa-bacterium': faBacterium,
    'fa-sun-dust': faSunDust,
    'fa-microscope': faMicroscope,
    'fa-gavel': faGavel,
    'fa-leaf': faLeaf,
    'fa-soap': faSoap,
    'fa-banana': faBanana,
    'fa-bottle-water': faBottleWater,
    'fa-tombstone': faTombstone,
    'fa-broom-wide': faBroomWide,
    'fa-badger-honey': faBadgerHoney,
    'fa-school': faSchool,
    'fa-coffee-pot': faCoffeePot,
    'fa-cubes-stacked': faCubesStacked,
    'fa-cauldron': faCauldron,
    'fa-ant': faAnt,
    'fa-face-raised-eyebrow': faFaceRaisedEyebrow,
    'fa-sword': faSword,
    'fa-umbrella': faUmbrella,
    'fa-boxing-glove': faBoxingGlove,
    'fa-hourglass-start': faHourglassStart,
    'fa-person-walking-with-cane': faPersonWalkingWithCane,
    'fa-virus': faVirus,
    'fa-guitar': faGuitar,
    'fa-square-root': faSquareRoot,
    'fa-scissors': faScissors,
    'fa-lightbulb': faLightbulb,
    'fa-scroll': faScroll,
    'fa-face-smile': faFaceSmile,
    'fa-message': faMessage,
    'fa-bone': faBone,
    'fa-code-branch': faCodeBranch,
    'fa-tree-palm': faTreePalm,
    'fa-conveyor-belt-empty': faConveyorBeltEmpty,
    'fa-house-night': faHouseNight,
    'fa-cactus': faCactus,
    'fa-pie': faPie,
    'fa-sailboat': faSailboat,
    'fa-frog': faFrog,
    'fa-elevator': faElevator,
    'fa-kiwi-bird': faKiwiBird,
    'fa-handcuffs': faHandcuffs,
    'fa-money-bill': faMoneyBill,
    'fa-key': faKey,
    'fa-plug': faPlug,
    'fa-dolphin': faDolphin,
    'fa-shuttle-space': faShuttleSpace,
    'fa-spray-can-sparkles': faSprayCanSparkles,
    'fa-flower-daffodil': faFlowerDaffodil,
    'fa-mug-tea': faMugTea,
    'fa-comment': faComment,
    'fa-hat-cowboy': faHatCowboy,
    'fa-boot': faBoot,
    'fa-skull-crossbones': faSkullCrossbones,
    'fa-down-to-line': faDownToLine,
    'fa-bird': faBird,
    'fa-bag-seedling': faBagSeedling,
    'fa-shirt-long-sleeve': faShirtLongSleeve,
    'fa-circle': faCircle,
    'fa-island-tropical': faIslandTropical,
    'fa-temperature-three-quarters': faTemperatureThreeQuarters,
    'fa-tire': faTire,
    'fa-clothes-hanger': faClothesHanger,
    'fa-globe': faGlobe,
    'fa-face-laugh-wink': faFaceLaughWink,
    'fa-whistle': faWhistle,
    'fa-music': faMusic,
    'fa-stomach': faStomach,
    'fa-magnet': faMagnet,
    'fa-baby': faBaby,
    'fa-sheep': faSheep,
    'fa-bottle-baby': faBottleBaby,
    'fa-moon': faMoon,
    'fa-spoon': faSpoon,
    'fa-comet': faComet,
    'fa-brain': faBrain,
    'fa-smoke': faSmoke,
    'fa-masks-theater': faMasksTheater,
    'fa-heart': faHeart,
    'fa-scarf': faScarf,
    'fa-book': faBook,
    'fa-cloud': faCloud,
    'fa-pickaxe': faPickaxe,
    'fa-atom': faAtom,
    'fa-fish': faFish,
    'fa-ski-boot-ski': faSkiBootSki,
    'fa-bench-tree': faBenchTree,
    'fa-booth-curtain': faBoothCurtain,
    'fa-utility-pole': faUtilityPole,
    'fa-coffee-bean': faCoffeeBean,
    'fa-handshake': faHandshake,
    'fa-battery-bolt': faBatteryBolt,
    'fa-tomato': faTomato,
    'fa-door-closed': faDoorClosed,
    'fa-water-arrow-up': faWaterArrowUp,
    'fa-dove': faDove,
    'fa-user': faUser,
    'fa-lasso': faLasso,
    'fa-beer-mug': faBeerMug,
    'fa-camera': faCamera,
    'fa-thought-bubble': faThoughtBubble,
    'fa-rabbit': faRabbit,
    'fa-spider': faSpider,
    'fa-timeline': faTimeline,
    'fa-chess': faChess,
    'fa-goal-net': faGoalNet,
    'fa-road': faRoad,
    'fa-syringe': faSyringe,
    'fa-block-brick': faBlockBrick,
    'fa-link': faLink,
    'fa-potato': faPotato,
    'fa-whiskey-glass-ice': faWhiskeyGlassIce,
    'fa-face-laugh-beam': faFaceLaughBeam,
    'fa-tent': faTent,
    'fa-train': faTrain,
    'fa-clock': faClock,
    'fa-turtle': faTurtle,
    'fa-pen-nib': faPenNib,
    'fa-mushroom': faMushroom,
    'fa-user-cowboy': faUserCowboy,
    'fa-roller-coaster': faRollerCoaster,
    'fa-teddy-bear': faTeddyBear,
    'fa-mountains': faMountains,
    'fa-square-root-variable': faSquareRootVariable,
    'fa-text': faText,
    'fa-lock-keyhole': faLockKeyhole,
    'fa-earth-oceania': faEarthOceania,
    'fa-dinosaur': faDinosaur,
    'fa-sheet-plastic': faSheetPlastic,
    'fa-toolbox': faToolbox,
    'fa-apple-whole': faAppleWhole,
    'fa-dragon': faDragon,
    'fa-sign-hanging': faSignHanging,
    'fa-shovel': faShovel,
    'fa-gear': faGear,
    'fa-person-military-pointing': faPersonMilitaryPointing,
    'fa-bee': faBee,
    'fa-pen-fancy': faPenFancy,
    'fa-ballot-check': faBallotCheck,
    'fa-umbrella-beach': faUmbrellaBeach,
    'fa-planet-ringed': faPlanetRinged,
    'fa-cloud-rain': faCloudRain,
    'fa-flower': faFlower,
    'fa-microwave': faMicrowave,
    'fa-bell': faBell,
    'fa-monkey': faMonkey,
    'fa-lips': faLips,
    'fa-turkey': faTurkey,
    'fa-eye': faEye,
    'fa-ear-listen': faEarListen,
    'fa-spider-web': faSpiderWeb,
    'fa-archway': faArchway,
    'fa-skeleton-ribs': faSkeletonRibs,
    'fa-drumstick': faDrumstick,
    'fa-worm': faWorm,
    'fa-person-fairy': faPersonFairy,
    'fa-user-hair-long': faUserHairLong,
    'fa-hand-fingers-crossed': faHandFingersCrossed,
    'fa-waffle': faWaffle,
    'fa-newspaper': faNewspaper,
    'fa-ear': faEar,
    'fa-hand': faHand,
    'fa-cat-space': faCatSpace,
    'fa-flag': faFlag,
    'fa-citrus': faCitrus,
    'fa-hand-back-point-up': faHandBackPointUp,
    'fa-nfc': faNfc,
    'fa-people-pulling': faPeoplePulling,
    'fa-bread-slice': faBreadSlice,
    'fa-droplet': faDroplet,
    'fa-shirt': faShirt,
    'fa-bow-arrow': faBowArrow,
    'fa-mitten': faMitten,
    'fa-teeth-open': faTeethOpen,
    'fa-dice': faDice,
    'fa-snowflake': faSnowflake,
    'fa-fishing-rod': faFishingRod,
    'fa-flower-tulip': faFlowerTulip,
    'fa-map': faMap,
    'fa-coin': faCoin,
    'fa-rectangle-wide': faRectangleWide,
    'fa-bolt-lightning': faBoltLightning,
    'fa-feather': faFeather,
    'fa-engine': faEngine,
    'fa-sunrise': faSunrise,
    'fa-cat': faCat,
    'fa-volleyball': faVolleyball,
    'fa-plant-wilt': faPlantWilt,
    'fa-face-smile-beam': faFaceSmileBeam,
    'fa-scale-balanced': faScaleBalanced,
    'fa-chess-king': faChessKing,
    'fa-crab': faCrab,
    'fa-fence': faFence,
    'fa-face-grin-tongue': faFaceGrinTongue,
    'fa-cake-candles': faCakeCandles,
    'fa-sun': faSun,
    'fa-gun': faGun,
    'fa-cards': faCards,
    'fa-bridge': faBridge,
    'fa-snake': faSnake,
    'fa-volcano': faVolcano,
    'fa-whale': faWhale,
    'fa-kite': faKite,
    'fa-bed': faBed,
    'fa-helmet-battle': faHelmetBattle,
    'fa-campfire': faCampfire,
    'fa-water': faWater,
    'fa-skull': faSkull,
    'fa-parachute-box': faParachuteBox,
    'fa-person-simple': faPersonSimple,
    'fa-dog': faDog,
    'fa-rings-wedding': faRingsWedding,
    'fa-pencil': faPencil,
    'fa-citrus-slice': faCitrusSlice,
    'fa-fan-table': faFanTable,
    'fa-bowl-rice': faBowlRice,
    'fa-nose': faNose,
    'fa-seedling': faSeedling,
    'fa-angle-up': faAngleUp,
    'fa-futbol': faFutbol,
    'fa-signature': faSignature,
    'fa-knife': faKnife,
    'fa-face-smiling-hands': faFaceSmilingHands,
    'fa-bug': faBug,
    'fa-wind': faWind,
    'fa-rug': faRug,
    'fa-ship': faShip,
    'fa-city': faCity,
    'fa-mouse-field': faMouseField,
    'fa-compass': faCompass,
    'fa-pig': faPig,
    'fa-land-mine-on': faLandMineOn,
    'fa-family': faFamily,
    'fa-truck-ladder': faTruckLadder,
    'fa-fireplace': faFireplace,
    'fa-paint-roller': faPaintRoller,
    'fa-hill-rockslide': faHillRockslide,
    'fa-onion': faOnion,
    'fa-skeleton': faSkeleton,
    'fa-camera-movie': faCameraMovie,
    'fa-smoking': faSmoking,
    'fa-coins': faCoins,
    'fa-racquet': faRacquet,
    'fa-horse': faHorse,
    'fa-thumbs-up': faThumbsUp,
    'fa-books': faBooks,
    'fa-candle-holder': faCandleHolder,
    'fa-drum': faDrum,
    'fa-reel': faReel,
    'fa-stars': faStars,
    'fa-cars': faCars,
    'fa-tree': faTree,
    'fa-candy-cane': faCandyCane,
    'fa-pen-to-square': faPenToSquare,
    'fa-wave': faWave,
    'fa-egg': faEgg,
    'fa-gem': faGem,
    'fa-hand-sparkles': faHandSparkles,
    'fa-code': faCode,
    'fa-face-sleeping': faFaceSleeping,
    'fa-hand-dots': faHandDots,
    'fa-money-bill-trend-up': faMoneyBillTrendUp,
    'fa-fire': faFire,
    'fa-trash': faTrash,
    'fa-bicycle': faBicycle,
    'fa-mattress-pillow': faMattressPillow,
    'fa-mask-ventilator': faMaskVentilator,
    'fa-calendar-days': faCalendarDays,
    'fa-paw-claws': faPawClaws,
    'fa-rocket': faRocket,
    'fa-lungs': faLungs,
    'fa-radio': faRadio,
    'fa-tooth': faTooth,
    'fa-blanket': faBlanket,
    'fa-bat': faBat,
    'fa-socks': faSocks,
    'fa-candy-bar': faCandyBar,
    'fa-palette': faPalette,
    'fa-dna': faDna,
    'fa-face-sad-tear': faFaceSadTear,
    'fa-arrow-down-1-9': faArrowDown19,
    'fa-person-running': faPersonRunning,
    'fa-cheese-swiss': faCheeseSwiss,
    'fa-lemon': faLemon,
    'fa-bowl-soft-serve': faBowlSoftServe,
    'fa-chess-queen': faChessQueen,
    'fa-brush': faBrush,
    'fa-kidneys': faKidneys,
    'fa-file-zipper': faFileZipper,
    'fa-eclipse': faEclipse,
    'fa-corn': faCorn,
    'fa-glass': faGlass,
    'fa-face-exhaling': faFaceExhaling,
    'fa-golf-flag-hole': faGolfFlagHole,
    'fa-mug': faMug,
    'fa-cart-shopping': faCartShopping,
    'fa-temperature-full': faTemperatureFull,
    'fa-table-picnic': faTablePicnic,
    'fa-face-smile-horns': faFaceSmileHorns,
    'fa-house-tree': faHouseTree,
    'fa-plate-utensils': faPlateUtensils,
    'fa-rectangle-barcode': faRectangleBarcode,
};
